import React, { useState } from "react";
import { Header } from "../../components/Header/Header";
import AccountSection from "../../components/AccountSection";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import WelcomeSection from "components/WelcomeSection";
import { ContactBannerImg, pricingbg } from "assets/index";
import AboutBusinessSection from "components/AboutBusinessSection";
import ContactBusinessSection from "components/contactBusinessSection";
import { Form, Input, Button, Row, Col } from "antd";

import { Link, useNavigate, useParams } from "react-router-dom";
import { contactUs } from "services/api/actions";
const Contact = () => {
  const gradient = "268.14deg, rgba(0, 0, 0, 0) 44.15%, rgba(0, 0, 0, 0.4) 47.69%, rgb(0, 0, 0) 136.94%";
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const style = {
    backgroundImage: `linear-gradient(${gradient}), url(${ContactBannerImg})`,
  };

  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    console.log("in handleSubmit");
    try {
      // await form.validateFields();
      const formData = await form.validateFields();
      console.log("Received values of form: ", formData);
      // ----------------------------------------

      try {
        setLoading(true);
        contactUs(formData)
          .then((response) => {
            setLoading(false);
            console.log("contact us res.data", response);

            if (response.data.meta.code === 404) {
              toast.error(response.data.meta.message);
            } else {
              navigate(`/thankyou?redirect=contact`);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("errors here", error);
          });
      } catch (error) {
        console.error("Error sent mail:", error);
      }

      // ----------------------------------------

      // const newData = {

      // };

      // await post("signUp", newData);
      form.resetFields();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Header />
      <WelcomeSection imageUrl={ContactBannerImg} title="Contact Us" title_ar="اتصل بنا" name="Feel Free to Reach Us" name_ar="لا تتردد في التواصل معنا" style={style} />

      <div className="ContactHeader">
        <div className="Contactheadercontent">
          <p className="textcolorwhite">Pricing Package</p>
          <h1 className="textcolorwhite">
            Affordable Plans <br /> For Customers
          </h1>
        </div>
      </div>
      {/* <AboutBusinessSection/> */}
      <ContactBusinessSection />

      <section id="contact">
        <div className="container">
          <div className="ContactDetailSection">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              name="ContactUsForm"
              // className="ContactUsForm"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t("your_name")} name="userName" rules={[{ required: true, message: t("enter_your_name") }]}>
                    <Input placeholder={t("your_name")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("enter_your_email")}
                    name="email"
                    rules={[
                      { required: true, message: t("enter_your_email") },
                      { type: "email", message: "Please input a valid email address!" },
                    ]}
                  >
                    <Input placeholder={t("enter_your_email")} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t("enter_your_number")} name="mobile" rules={[{ required: true, message: t("enter_your_number") }]}>
                    <Input placeholder={t("enter_your_number")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t("Subject")} name="subject" rules={[{ required: true, message: t("enter_subject") }]}>
                    <Input placeholder={t("Subject")} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={t("enter_your_message")} name="description" rules={[{ required: true, message: t("enter_your_message") }]}>
                <Input.TextArea rows={4} placeholder={t("enter_your_message")} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">{t("submit")}</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </section>

      <AccountSection backgroundImage={pricingbg} />
      <Footer />
    </>
  );
};

export default Contact;
