import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Button } from "antd";
import Footer from "../../components/Footer";
import { Header } from "../../components/Header/Header";
import AccountSection from "../../components/AccountSection";
import pricingbg from "../../assets/images/pricingBottombg.png";
import { signup, subscription_list, googleSignIn } from "../../services/api/actions";
import { useNavigate, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { BounceLoader
} from 'react-spinners';

const override = {
  borderWidth:"4px"
};
const Pricing = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //Get query param
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const query_param = params.get("sn");
  const email = params.get("email");
  const pricingRef = useRef(null);


  console.log('email',email)

  useEffect(() => {
    // Scroll to the div when the component loads
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    try {
      subscription_list({})
        .then((response) => {
          console.log("pack", response.data.data);
          setPackages(response.data.data);
        })
        .catch((error) => {
          console.log("errors here");
        });
    } catch (error) {
      console.error("Error package list issue:", error);
    }
  }, [setPackages]);

  const handleClick = (product_id, type) => {
    console.log("typers ", type);
    localStorage.setItem("product_id", product_id);
    localStorage.removeItem("packages");

    if (type == 1) {
      localStorage.setItem("packages", "free");
      navigate(`/thankyou`);
    } else {
      localStorage.setItem("packages", product_id);
      navigate(`/thankyou`);
      //  navigate("/stripe", { state: { product_id } });
    }

  };
  const handleRegister = (product_id, type, package_id) => {
    localStorage.setItem("product_id", product_id);
    localStorage.removeItem("packages");


    if (email){

      setLoading(true)
      console.log('email if',email)

      try {
        googleSignIn({email,package_id})
            .then((response) => {

              navigate('/thankyou');
            })
            .catch((error) => {
              console.log('errors here', error)
            })
      } catch (error) {
        console.error('Error package list issue:', error)
      }
    }else {
      if (type == 1) {
        localStorage.setItem("packages", "free");
        navigate(`/signup`);
      } else {
        localStorage.setItem("packages", product_id);
        navigate(`/signup`);
      }
    }

  };

  return (
    <>
      <Header />


      <div className="pricingheader">
        <div className="pricingheadercontent">
          <p className="textcolorwhite">Package Pricing</p>
          <h1 className="textcolorwhite">
            Affordable Plans <br /> For Customers
          </h1>
        </div>
      </div>

      { loading &&
      <div className="body-overlay">
        <BounceLoader
            color="#2d358c"  aria-label="Loading Spinner"
            data-testid="loader" loading={true} size={100} cssOverride={override}/>
      </div>
      }

      <div className="container">
        <div className="TextSection">
          <h1>Discover our latest client projects</h1>
          <p>Leverage agile frameworks to provide a robust synopsis for high level overviews.Iterative approaches to corporate strategy foster collaborative thinking to further the overal.</p>
        </div>
        <div className="Pricing" ref={pricingRef}>
          <p>Simple Pricing Option</p>
          <h1>
            Choose the <span className="textcolorgreen">Right Plan</span> for You <br />
            and Your Team
          </h1>
          <Row gutter={[28, 28]}>
            {packages.map((pack, index) => (
              <Col xs={24} sm={24} md={12} lg={8} xl={8} key={pack.id}>
                <Card title={pack.type == 1 ? "Free" : pack.title} bordered={false}>
                  {/* {pack.type !== 1 && ( */}
                  <div className="billingInfo">
                    <h2>
                      ${pack.price} <span>/ {pack.duration}</span>
                    </h2>

                    <div className="BBillText">
                      {/*<p> Billed as ${pack.duration == 'month' ? pack.price * 12 : pack.price} per year</p>*/}
                      <p>
                        {" "}
                        Billed as ${pack.price} <span>/ {pack.duration}</span>
                      </p>
                    </div>
                  </div>
                  {/* )} */}
                  <ul className="features-list">
                    {/*<li>{pack.description}</li>*/}
                    <li className="features-list-item">{parse(pack.description)}</li>
                    {/*<div />*/}
                    {/* <li>Unlimited feedback</li> */}
                    {/* <li>Weekly team Feedback Friday</li>
                    <li>Custom Kudos +9 illustration</li>
                    <li>Team feedback history (30)</li>
                    <li>Personal feedback</li> */}
                  </ul>

                  {query_param === "yes" ? (
                    <div className="GetStartedBtn">
                      {/*<Link to={`/EditUser/${pack.id}`}>*/}
                      <Button type="primary" onClick={() => handleClick(pack.product_id, pack.type)}>
                        Get Started
                      </Button>
                      {/*</Link>*/}
                    </div>
                  ) : (
                    <div className="GetStartedBtn">
                      <Button type="primary" onClick={() => handleRegister(pack.product_id, pack.type, pack.id)}>
                        <a style={{ textDecoration: "none" }}>Choose Plan</a>
                      </Button>
                    </div>
                  )}
                </Card>
              </Col>
            ))}

            {/*<Col xs={24} sm={24} md={12} lg={8} xl={8}>*/}
            {/*  <Card title="Advanced" bordered={false} >*/}
            {/*    <div className="billingInfo" >*/}
            {/*      <h2 >*/}
            {/*        $9.99 <span>/ month</span>*/}
            {/*      </h2>*/}

            {/*      <div className="AdvBillText" >*/}
            {/*        <p>Billed as $199 per year</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <ul className="features-list">*/}
            {/*      <li>Unlimited members</li>*/}
            {/*      <li>Unlimited feedback</li>*/}
            {/*      <li>Weekly team Feedback Friday</li>*/}
            {/*      <li>Custom Kudos +9 illustration</li>*/}
            {/*      <li>Team feedback history (30 items)</li>*/}
            {/*      <li>Personal feedback history (6 items)</li>*/}
            {/*      <li>Slack integration</li>*/}
            {/*    </ul>*/}

            {/*    <div className="GetStartedBtn">*/}
            {/*      <Button type="primary" >*/}
            {/*        Get Started*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={24} sm={24} md={12} lg={8} xl={8}>*/}
            {/*  <Card title="Pro" bordered={false} >*/}
            {/*    <div className="billingInfo" >*/}
            {/*      <h2 >*/}
            {/*        $9.99 <span>/ month</span>*/}
            {/*      </h2>*/}

            {/*      <div className="AdvBillText" >*/}
            {/*        <p>Billed as $199 per year</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <ul className="features-list">*/}
            {/*      <li>Unlimited members</li>*/}
            {/*      <li>Unlimited feedback</li>*/}
            {/*      <li>Weekly team Feedback Friday</li>*/}
            {/*      <li>Custom Kudos +9 illustration</li>*/}
            {/*      <li>Team feedback history (30 items)</li>*/}
            {/*      <li>Personal feedback history (6 items)</li>*/}
            {/*      <li>Slack integration</li>*/}
            {/*    </ul>*/}
            {/*    <div className="GetStartedBtn">*/}
            {/*      <Button type="primary" >*/}
            {/*        Get Started*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={24} sm={24} md={12} lg={8} xl={8}>*/}
            {/*  <Card title="Basic" bordered={false} >*/}
            {/*    <div className="billingInfo" >*/}
            {/*      <h2 >*/}
            {/*        $7.99 <span>/ month</span>*/}
            {/*      </h2>*/}

            {/*      <div className="BBillText" >*/}
            {/*        <p>Billed as $96 per year</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <ul className="features-list">*/}
            {/*      <li>Unlimited members</li>*/}
            {/*      <li>Unlimited feedback</li>*/}
            {/*      <li>Weekly team Feedback Friday</li>*/}
            {/*      <li>Custom Kudos +9 illustration</li>*/}
            {/*      <li>Team feedback history (30)</li>*/}
            {/*      <li>Personal feedback</li>*/}
            {/*    </ul>*/}
            {/*    <div className="GetStartedBtn">*/}
            {/*      <Button type="primary" >*/}
            {/*        Get Started*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={24} sm={24} md={12} lg={8} xl={8}>*/}
            {/*  <Card title="Advanced" bordered={false} >*/}
            {/*    <div className="billingInfo" >*/}
            {/*      <h2 >*/}
            {/*        $9.99 <span>/ month</span>*/}
            {/*      </h2>*/}

            {/*      <div className="AdvBillText" >*/}
            {/*        <p>Billed as $199 per year</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <ul className="features-list">*/}
            {/*      <li>Unlimited members</li>*/}
            {/*      <li>Unlimited feedback</li>*/}
            {/*      <li>Weekly team Feedback Friday</li>*/}
            {/*      <li>Custom Kudos +9 illustration</li>*/}
            {/*      <li>Team feedback history (30 items)</li>*/}
            {/*      <li>Personal feedback history (6 items)</li>*/}
            {/*      <li>Slack integration</li>*/}
            {/*    </ul>*/}
            {/*    <div className="GetStartedBtn">*/}
            {/*      <Button type="primary" >*/}
            {/*        Get Started*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={24} sm={24} md={12} lg={8} xl={8}>*/}
            {/*  <Card title="Pro" bordered={false} >*/}
            {/*    <div className="billingInfo" >*/}
            {/*      <h2 >*/}
            {/*        $9.99 <span>/ month</span>*/}
            {/*      </h2>*/}

            {/*      <div className="AdvBillText" >*/}
            {/*        <p>Billed as $199 per year</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <ul className="features-list">*/}
            {/*      <li>Unlimited members</li>*/}
            {/*      <li>Unlimited feedback</li>*/}
            {/*      <li>Weekly team Feedback Friday</li>*/}
            {/*      <li>Custom Kudos +9 illustration</li>*/}
            {/*      <li>Team feedback history (30 items)</li>*/}
            {/*      <li>Personal feedback history (6 items)</li>*/}
            {/*      <li>Slack integration</li>*/}
            {/*    </ul>*/}
            {/*    <div className="GetStartedBtn">*/}
            {/*      <Button type="primary" >*/}
            {/*        Get Started*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
          </Row>
        </div>
      </div>

      <AccountSection backgroundImage={pricingbg} />
      <Footer />
    </>
  );
};

export default Pricing;
